import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Grid from "@mui/material/Grid";
import TimePicker from "@mui/lab/TimePicker";
import ruLocale from "date-fns/locale/ru";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import pink from "@mui/material/colors/pink";
import green from "@mui/material/colors/green";
import Zoom from "@mui/material/Zoom";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Pagination from "@mui/material/Pagination";
import { FixedSizeList as List } from "react-window";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import moment from "moment";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
export default function ViewItem({
  checkPanel,
  fieldID,
  name,
  phone,
  leadName,
  viewTime,
  viewDate,
  _register,
  truncate,
  ended,
  setChanged,
  _control,
  remove,
  deleteItem,
  firstname,
  lastname,
  feedback,
  setValue,
  comment,
  MakeRepalce,
  leadid,
  fail,
  changed,
  setContact,
  top100Films,
  source,
}) {

    const [deal, setDeal] = useState(null);



    useEffect(() => {
        if (!deal){
            fetch(`https://labetsky-estate.bitrix24.ru/rest/1/ygyxhjvbajq7ech1/crm.deal.get?id=${leadid}`).then(data => data.json()).then(data => {
                setDeal(data.result);
                console.log(data.result);
            })
        }
    })
  return (
    <Accordion
      // expanded={expanded === `panel${fieldID}`}
      // onChange={handleChange(`panel${fieldID}`)}
      key={fieldID}
      //        className={(category == 'fail' && fail === true) ? ' fail' :
      // (category == 'success' && ended === true) ? 'success' :
      //     (category == 'new' && ended === false && fail === false) ? 'new' : category == 'all' ? '' : 'hiddenView'}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        xs={{ width: "100%" }}
      >
        {checkPanel(`panel${fieldID}`, name, phone) ? (
          <Table
            size="small"
            sx={{ width: window.outerWidth > 850 ? "80%" : "100%" }}
          >
            <TableHead className={"hidden"}>
              {window.outerWidth > 850 ? (
                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    width: "100%",
                  }}
                >
                  <TableCell>Дата просмотра</TableCell>
                  <TableCell align="center">Время</TableCell>
                  <TableCell align="center">Имя</TableCell>
                  <TableCell align="center">Телефон</TableCell>
                  <TableCell align="center">Комментарий</TableCell>
                  <TableCell align="center">Обратная связь</TableCell>
                </TableRow>
              ) : (
                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1.4fr 0.6fr",
                    width: "100%",
                  }}
                >
                  <TableCell align="left">Дата, время просмотра</TableCell>
                  <TableCell align="center">Имя</TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {window.outerWidth > 850 ? (
                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    width: "100%",
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                  // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {window.leadid === "all" ? (
                    <>
                      <TableCell>{deal && deal.TITLE}</TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        {moment(new Date(Date.parse(viewDate))).format(
                          "DD.MM.YYYY"
                        )}{" "}
                        {viewTime
                          ? moment(new Date(Date.parse(viewTime))).format(
                              "HH:mm"
                            )
                          : "—"}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        {moment(new Date(Date.parse(viewDate))).format(
                          "DD.MM.YYYY"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {viewTime
                          ? moment(new Date(Date.parse(viewTime))).format(
                              "HH:mm"
                            )
                          : "—"}
                      </TableCell>
                    </>
                  )}

                  <TableCell align="center">{name}</TableCell>
                  <TableCell align="center">{phone}</TableCell>
                  <TableCell align="center">{truncate(comment)}</TableCell>
                  <TableCell align="center">{truncate(feedback)}</TableCell>
                </TableRow>
              ) : (
                <>
                  {window.leadid === "all" && (
                <TableRow>
                <TableCell align="left" sx={{border: 0}}>
                {deal?.TITLE}
                  </TableCell>
                
                  </TableRow>
                )}
                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1.4fr 0.6fr",
                    width: "100%",
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                  // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  
                  <TableCell align="left">
                    {moment(new Date(Date.parse(viewDate))).format(
                      "DD.MM.YYYY"
                    )}{" "}
                    {viewTime
                      ? moment(new Date(Date.parse(viewTime))).format("HH:mm")
                      : "—"}
                  </TableCell>

                  <TableCell align="center">{name}</TableCell>
                </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        ) : (
          <Typography
            sx={{
              width: "80%",
              flexShrink: 0,
              alignItems: "center",
              display: "flex",
              paddingLeft: "15px",
            }}
          >
            Новый показ
          </Typography>
        )}

        <Typography
          sx={{
            width: window.outerWidth > 850 ? "20%" : "100%",
            display: "flex",
            justifyContent: window.outerWidth > 850 ? "flex-end" : "center",
            alignItems: "center",
          }}
        >
          <Tooltip title="Показ проведён">
            <Checkbox
              {..._register(`items[${fieldID}].ended`)}
              name={`items[${fieldID}].ended`}
              defaultChecked={ended}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 28 },
                color: "#e0e0e0",
                "&.Mui-checked": {
                  color: green[600],
                },
              }}
              onFocus={(event) => event.stopPropagation()}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(event, checked) => {
                setValue(`items.[${fieldID}].ended`, checked);
                MakeRepalce(checked, fieldID, "ended");
                if (checked && fail) {
                  setValue(`items.[${fieldID}].fail`, !checked);
                  MakeRepalce(!checked, fieldID, "fail");
                }
              }}
              checked={ended}
              icon={<CheckCircleOutlinedIcon />}
              checkedIcon={<CheckCircleIcon />}
            />
          </Tooltip>
          <Tooltip title="Встреча отменена">
            <Checkbox
              {..._register(`items[${fieldID}].fail`)}
              name={`items[${fieldID}].fail`}
              defaultChecked={fail}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 28 },
                color: "#e0e0e0",
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
              // disabled={fields[fieldID].ended ? true : false}
              onFocus={(event) => event.stopPropagation()}
              onClick={(event) => event.stopPropagation()}
              onChange={(event, checked) => {
                setValue(`items.[${fieldID}].fail`, checked);
                if (checked && ended) {
                  setValue(`items.[${fieldID}].ended`, !checked);
                }
                MakeRepalce(!checked, fieldID, "ended");
                MakeRepalce(checked, fieldID, "fail");
              }}
              icon={<CancelOutlinedIcon />}
              checkedIcon={<CancelIcon />}
              checked={fail}
            />
          </Tooltip>
          {!changed && name && phone ? (
            <Tooltip title="Привязать контакт к полю Покупатель">
              <IconButton
                onFocus={(event) => event.stopPropagation()}
                onClick={(event) => {
                  event.stopPropagation();
                  setContact(name, phone);
                }}
              >
                <PersonAddAltIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <input
          name={`items[${fieldID}].firstname`}
          {..._register(`items[${fieldID}].firstname`)}
          defaultValue={firstname}
          type={"hidden"}
        />
        <input
          name={`items[${fieldID}].lastname`}
          {..._register(`items[${fieldID}].lastname`)}
          defaultValue={lastname}
          type={"hidden"}
        />
        <Grid container rowSpacing={3} columnSpacing={1} key={fieldID}>
          <Grid item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ruLocale}
            >
              <Controller
                name={`items[${fieldID}].applicationDate`}
                {..._register(`items[${fieldID}].applicationDate`)}
                control={_control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    label="Дата обращения"
                    onChange={(e) => {
                      field.onChange(e);
                      setChanged(true);
                    }}
                    renderInput={(props) => (
                      <TextField fullWidth {...props} required />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ruLocale}
            >
              <Controller
                name={`items[${fieldID}].viewDate`}
                {..._register(`items[${fieldID}].viewDate`)}
                control={_control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    label="Дата показа"
                    // selected={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      setChanged(true);
                    }}
                    renderInput={(props) => <TextField fullWidth {...props} />}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ruLocale}
            >
              <Controller
                name={`items[${fieldID}].viewTime`}
                {..._register(`items[${fieldID}].viewTime`)}
                control={_control}
                render={({ field }) => (
                  <TimePicker
                    value={field.value}
                    label="Время показа"
                    // selected={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      setChanged(true);
                    }}
                    renderInput={(props) => <TextField fullWidth {...props} />}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6}>
            <TextField
              name={`items[${fieldID}].name`}
              {..._register(`items[${fieldID}].name`)}
              label="Имя клиента"
              defaultValue={name.value}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Телефон клиента"
              {..._register(`items[${fieldID}].phone`)}
              fullWidth
              name={`items[${fieldID}].phone`}
              value={phone.value}
              required
              helperText={"Введите в формате +79999999999"}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name={`items[${fieldID}].source`}
              {..._register(`items[${fieldID}].source`)}
              control={_control}
              defaultValue={source}
              render={({ field, onChange }) => (
                <Autocomplete
                  value={field.value}
                  onChange={(e, value) => {
                    setValue(`items[${fieldID}].source`, value);
                    // console.log(value)
                  }}
                  options={top100Films.map((option) => option.title)}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      onChange={(e) => {
                        setValue(`items[${fieldID}].source`, e.target.value);
                      }}
                      label="Источник"
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Комментарий"
              multiline
              name={`items[${fieldID}].comment`}
              rows={2}
              fullWidth
              // value={comment.value}
              {..._register(`items[${fieldID}].comment`)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Обратная связь"
              multiline
              name={`items[${fieldID}].feedback`}
              rows={2}
              fullWidth
              // value={feedback.value}
              {..._register(`items[${fieldID}].feedback`)}
            />
          </Grid>

          <Grid item xs={12} sx={{ alignItems: "center", display: "flex" }}>
            <Controller
              name={`items[${fieldID}].isAgent`}
              {..._register(`items[${fieldID}].isAgent`)}
              control={_control}
              render={({ field }) => (
                <Checkbox
                  {..._register(`items[${fieldID}].isAgent`)}
                  checked={field.value}
                />
              )}
            />
            <span style={{ marginLeft: "10px" }}>Агент</span>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant={"contained"}
              color={"error"}
              onClick={() => {
                console.log(remove(deleteItem(fieldID)));
                setChanged(true);
              }}
            >
              Удалить
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
