import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Grid from "@mui/material/Grid";
import TimePicker from "@mui/lab/TimePicker";
import ruLocale from "date-fns/locale/ru";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import pink from "@mui/material/colors/pink";
import green from "@mui/material/colors/green";
import Zoom from "@mui/material/Zoom";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Pagination from "@mui/material/Pagination";
import { FixedSizeList as List } from "react-window";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import moment from "moment";

import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import ViewItem from "./ViewItem";
function FieldsComponent({
  uploadingData,
  fields,
  _control,
  setChanged,
  _register,
  setValue,
  remove,
  category,
  replace,
  watch,
  prepend,
  setSaving,
  deleteItem,
  changed,
  setContact,
  handleSubmit,
  onError,
  onSubmit,
}) {
  // console.log(fields)
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);

  const handlePage = (event, value) => {
    setPage(value);
  };
  const itemsPerPage = 5;
  function necessaryArr() {
    let nextInd = page * itemsPerPage - 1;
    let prevInd = nextInd - itemsPerPage;
    console.log(prevInd, nextInd);
  }
  let slicedArr = fields.slice(
    page * itemsPerPage - itemsPerPage,
    page * itemsPerPage
  );
  const top100Films = [
    { title: "Циан" },
    { title: "Авито" },
    { title: "Яндекс Недвижимость" },
    { title: "Дом Клик" },
    { title: "Смотрел другой мой объект" },
    { title: "Передал коллега" },
    { title: "Другое" },
  ];
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    // console.log(panel)
    setExpanded(isExpanded ? panel : false);
  };
  let val = watch("items");
  let date = new Date();
  function MakeRepalce(checked, fieldID, field) {
    setTimeout(() => {
      let fs = fields.filter((item) => item.fieldID == fieldID);
      console.log(fields, fs);
      const fVal = fs[0][field];
      // const vVal = vs[0][field]

      // console.log((fVal != vVal &&  && fVal != checked))
      if (fVal != checked) {
        replace(val);

        // let up = update(fieldID, vVal[fieldID])
        // console.log(up)
        // setLoading(false)
      } else {
        console.log("success!");
        console.log(fVal, checked);
        // setLoading(false)
      }
      // setSaving(false)
    }, 0.5);
  }

  useEffect(() => {
    console.log("val", val);
    console.log("fields", fields);
  }, []);
  function checkPanel(panelName, name, phone) {
    if (panelName === expanded) {
      return false;
    } else {
      if (name && phone && !(panelName === expanded)) {
        return true;
      }
    }
  }
  function truncate(str) {
    return str.length > 20 ? str.substring(0, 20) + "..." : str;
  }
  return (
    <>
      <Fade
        in={loading}
        // style={{
        //     transitionDelay: loading ? '800ms' : '0ms',
        // }}
        unmountOnExit
      >
        <CircularProgress />
      </Fade>

      <Box
        className={"headers"}
        sx={{
          marginBottom: "15px",
          backgroundColor: "#eee",
          borderRadius: "5px",
        }}
      >
        <Table
          size="small"
          sx={{ width: window.outerWidth > 850 ? "80%" : "100%" }}
        >
          <TableHead>
            {window.outerWidth > 850 ? (
              <TableRow
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                  width: "100%",
                }}
              >
                {window.leadid === "all" ? (
                  <>
                    <TableCell sx={{ borderBottom: "none" }}>Cделка</TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      Дата, время просмотра
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell sx={{ borderBottom: "none" }}>
                      Дата просмотра
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }} align="center">
                      Время
                    </TableCell>
                  </>
                )}

                <TableCell sx={{ borderBottom: "none" }} align="center">
                  Имя
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }} align="center">
                  Телефон
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }} align="center">
                  Комментарий
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }} align="center">
                  Обратная связь
                </TableCell>
              </TableRow>
            ) : (
              <TableRow
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1.4fr 0.6fr",
                  width: "100%",
                }}
              >
                <TableCell align="left">Дата, время просмотра</TableCell>
                <TableCell align="center">Имя</TableCell>
              </TableRow>
            )}
          </TableHead>
        </Table>
      </Box>
      {!slicedArr.length ? <Typography>Показы не найдены</Typography> : null}
      {!uploadingData
        ? slicedArr.map(
            (
              {
                applicationDate,
                viewDate,
                viewTime,
                name,
                phone,
                ended,
                comment,
                feedback,
                source,
                fail,
                firstname,
                lastname,
                fieldID,
                leadName,
                leadid
              },
              index
            ) => {
              return <ViewItem applicationDate 
              key={`${leadid}-${index}`}
              checkPanel={checkPanel}
              fieldID={fieldID}
              name={name}
              phone={phone}
              leadName={leadName}
              viewTime={viewTime}
              viewDate={viewDate}
              leadid={leadid}
              _register={_register}
              truncate={truncate}
              ended={ended}
              setChanged={setChanged}
              _control={_control}
              remove={remove}
              deleteItem={deleteItem}
              firstname={firstname}
              lastname={lastname}
              feedback={feedback}
              setValue={setValue}
              comment={comment}
              MakeRepalce={MakeRepalce}
              fail={fail}
              changed={changed}
              setContact={setContact}
              top100Films={top100Films}
              source={source}
              />
            })
            
          
        : null}
      <Stack spacing={2}>
        <Pagination
          className={"pagination"}
          count={Math.ceil(fields.length / itemsPerPage)}
          page={page}
          onChange={handlePage}
        />
      </Stack>
    </>
  );
}

export default FieldsComponent;
