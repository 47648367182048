import React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
// import bitrix from "./Bitrix";
import Button from '@mui/material/Button';
import View from "./View";
import Typography from "@mui/material/Typography";
import Zoom from '@mui/material/Zoom';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Icon } from '@mui/material';
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
function ViewList({setLeadId, setLeadName}) {

  const [deals, setDeals] = useState([])
  const [searchText, setSearchText] = useState('')
    const [page, setPage] = useState(0)
  function setPageType(num){
      setPage(num)
      console.log(num)
      if (num === 1){
          console.log('setting LeadID')
          setLeadId('all')
      }
  }
  let searched = searchText ? deals.filter(el=> el.TITLE.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) : deals
  useEffect(() => {
    
        
            fetch(`https://labetskyservices.ru/getDeals?userid=${window.userid}`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {


                setDeals(data['deals'])
                console.log(deals)


            })
        
    
    //     bitrix.deals.list({})
    //   .then(({ result }) => {
    //     const titles = result.map((e) => e.TITLE)
    //     console.log(titles)
    //   })
  }, []);

  return (
    <>
      <Typography variant="h4" component="div" gutterBottom>
        Ваши сделки
      </Typography>
      <TextField id="standard-basic" label="Поиск по названию" placeholder="название сделки" variant="standard" sx={{margin: '10px 0px'}} value={searchText} onChange={(e)=> setSearchText(e.target.value)}/>
      
      <List>
        {searched.map((deal, index) => {
          return (
         
            <Paper elevation={8} sx={{marginBottom: "10px"}} key={index}>
            <Button  onClick={()=> setLeadId(deal.ID)} sx={{width: '100%', color: "#000", justifyContent: 'space-between', padding: '10px 10px'}} align="left" variant="text">
                {deal.TITLE}
                <div className="listRight">
                    {deal.type === 'helper' ? <Chip label="помощник" color="primary" sx={{marginRight: '10px'}} /> : null }
                
                <Icon color="primary" sx={{width: '25px', height : '25px', alignItems: 'center', display: 'flex'}}><ArrowForwardIosIcon/></Icon>
                </div>
               
            </Button>
        </Paper>
              
          );
        })}
      </List>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
            showLabels
            value={page}
            onChange={(event, newValue) => {
                setPageType(newValue);
            }}
        >
            <BottomNavigationAction label="Сделки" />
            <BottomNavigationAction label="Все показы" />
        </BottomNavigation>
        </Paper>
    </>
  );
}

export default ViewList;
