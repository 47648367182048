import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import ViewList from "./List";
import View from "./View";
import { useState, useEffect } from "react";
function App(){
    
    const [leadid, setLeadId] = useState(window.leadid)
    const [leadname, setLeadname] = useState(window.dealname)

    function setID(id){
        if (id !== 'all'){
            id = parseInt(id)
            window.leadid = id
            console.log(window.leadid)
            setLeadId(id)

        }
        if (id === 'all'){
            window.leadid = id
            console.log(window.leadid)
            setLeadId(id)
        }

    }
    function setDealName(name){
        window.dealname = name
        setLeadname(name)

    }
    

    console.log(leadid)
    return(
    //     <BrowserRouter>
    //     <Routes>
    //     <Route path="/" element={<Navigate to='/index' />}/>    
    //     <Route path="/index" exact element={window.main ? <ViewList /> : <Navigate to={`/views?${window.leadid}`} />} >
    //     </Route>
    //     <Route path='/leads' exact element={ <ViewList />}/>
    //     <Route path="/view/:leadid"  element={<View/>} />
         
        
    //   </Routes>
    //   </BrowserRouter>

    

        leadid  ? <View setLeadId={setID}  /> :   <ViewList  setLeadId={setID} setLeadName={setDealName} />
    
    )
}

export default App;
