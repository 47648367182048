import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import ViewList from "./List";
import "@fontsource/montserrat"; 
import { createTheme, ThemeProvider } from "@mui/material/styles";
import View from "./View";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'

import reportWebVitals from "./reportWebVitals";
// console.log = console.warn = console.error = () => {};

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
      <App />
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
