import React, {useEffect, useState} from "react";
import {useFieldArray, useForm, useWatch} from "react-hook-form";
import Button from '@mui/material/Button';
import {createTheme} from '@mui/material/styles';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from '@mui/material/Alert';
import FieldsComponent from "./views";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import {useParams} from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {Link} from 'react-router-dom'
import Typography from "@mui/material/Typography";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import moment from "moment";


// window.leadid = 2



let renderCount = 0;


function View({setLeadId}) {
    const {register, control, handleSubmit, reset, setValue, watch} = useForm()
    const [uploadingData, setUploadingData] = useState(false)
    const [saving, setSaving] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)

    const [category, setCategory] = useState('new')
    const [processSaving, setProcessSaving] = useState(false)
    const [wasUploaded, setWasUploaded] = useState(false)
    const [error, setError] = useState(null)
    const [changed, setChanged] = useState(false)
    const [wasDownloaded, setWasDownloaded] = useState(false)
    const {fields, append, remove, update, replace, prepend} = useFieldArray({

        control,
        name: 'items'
    })
    const [page, setPage] = useState(1)
    function setPageType(num){
        setPage(num)
        console.log(num)
        if (num === 1){
            console.log('setting LeadID')
            setLeadId('all')
        }
        else if (num === 0){
            setLeadId(null)
        }
    }
    const onSubmit = function (data, e) {
        setProcessSaving(true)


        if (window.leadid === 'all'){
            data = {leadid: window.leadid, data: data, userid: window.userid}
            // console.log(JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            };
            fetch('https://labetskyservices.ru/allViews', requestOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    data['res'] == 'ok' ? setError(false) : setError(true)
                    setProcessSaving(false)
                    setWasUploaded(true)
                    setWasDownloaded(false)
                    setChanged(false)
                })
        }
        else{
            data = {leadid: window.leadid, data: data}
            // console.log(JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            };
            fetch('https://labetskyservices.ru/views', requestOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    data['res'] == 'ok' ? setError(false) : setError(true)
                    setProcessSaving(false)
                    setWasUploaded(true)
                    setWasDownloaded(false)
                    setChanged(false)
                })
        }



    }
    function setContact(name, phone) {
        let data = {dealid: window.leadid, name: name, phone: phone}
        // console.log(JSON.stringify(data))
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        };

        fetch('https://labetskyservices.ru/setContact', requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
              console.log(data)
            })
    }
    function deleteItem(itemID){
        console.log(itemID, indexedFields)
        for (let i = 0; i < indexedFields.length; i++) {
            if (indexedFields[i].fieldID == itemID){
                console.log(i)
                return i
            }
        }

    }
    let indexedFields = fields.map((item, index) => {
        item['fieldID'] = index
        return item
    })
    let filteredFields = indexedFields.filter( (item) => {

       if (category == 'success'){
           if (item.ended){
               return true
           }
           else{
               return false
           }
       }
       else if (category == 'fail'){
           if (item.fail){
               return true
           }
           else{
               return false
           }
       }
       else if (category == 'all'){
           return true
       }
       else if (category == 'new'){
           if (!item.fail && !item.ended){
               return true
           }
           else{
               return false
           }
       }
    }  )
    console.log(filteredFields)
    // useWatch('items')
    // const val = useWatch({name:'items', control})
    // const watchFieldArray = watch("items");
    // const controlledFields = fields.map((field, index) => {
    //     return {
    //         ...field,
    //         ...watchFieldArray[index]
    //     };
    // });
    useEffect(() => {
        
        console.log(window.leadid)
        if (!wasDownloaded){
            

            setUploadingData(true)
            fetch(
                `https://labetsky-estate.bitrix24.ru/rest/1/ygyxhjvbajq7ech1/crm.deal.list.json?filter[ID]=${window.leadid}`
              )
                .then((data) => data.json())
                .then((data) => {
                  data = data["result"][0];
                  window.userdealid = parseInt(data["ASSIGNED_BY_ID"]);
                })
            if (window.leadid == 'all'){
                fetch(`https://labetskyservices.ru/allViews?userid=${window.userid}`)
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        reset({
                            items: data.views
                        });

                        setUploadingData(false)
                        setWasDownloaded(true)



                    });
            }
            else{
                fetch(`https://labetskyservices.ru/views?leadid=${window.leadid}`)
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        reset({
                            items: data.views
                        });

                        setUploadingData(false)
                        setWasDownloaded(true)



                    });
            }

        }



            setShouldUpdate(!shouldUpdate)
            console.log(fields)
    }, [processSaving, wasUploaded, error, wasDownloaded]);


    const onError = (errors, e) => console.log(errors, e);

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });
    // console.log(fields.filter(data => data.fail === false), fields)
    return (
        <>
       
        <IconButton aria-label="delete" sx={{marginBottom: '20px'}} onClick={()=>setLeadId(false)}>
        <ArrowBackIosNewIcon/>

      </IconButton>
            {window.dealname && window.leadid !== 'all' ? <Typography variant={'h5'} sx={{margin: '10px 0'}}>Сделка: {window.dealname}</Typography> : null}
            <Backdrop open={!wasDownloaded}
                      sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}

            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <form onSubmit={handleSubmit(onSubmit, onError)} onChange={() => setChanged(true)}>
                <Grid >
                    <Grid item xs={6}>
                <FormControl sx={{m: 1, minWidth: 350, marginLeft: 0, marginBottom: 2}}>
                    <InputLabel >Категория</InputLabel>
                    <Select
                        value={category}
                        label="Категория"
                        onChange={(e) => {
                            setCategory(e.target.value)
                            console.log(fields)
                        }}
                    >
                        <MenuItem value={'new'}>Запланированные показы</MenuItem>
                        <MenuItem value={'fail'}>Показ отменён</MenuItem>
                        <MenuItem value={'success'}>Проведенные показы</MenuItem>


                    </Select>
                </FormControl>
                    </Grid>
                <Grid item xs={6}>
                {!uploadingData && window.leadid !== 'all' ? <Button sx={{mb: 3}} variant="contained" className={'addView'} onClick={() => prepend({
                    applicationDate: moment(new Date()).format('YYYY-MM-DD'),
                    viewDate: '',
                    viewTime: '',
                    name: '',
                    leadName : window.dealname,
                    phone: '',
                    ended: category == 'success'  ? true : (category == 'new' || category == 'fail' || category == 'all') ? false : false,
                    comment: '',
                    feedback: '',
                    source: '',
                    isAgent: false,
                    addedToCalendar: false,
                    userID : parseInt(window.userdealid),
                    fail: category == 'fail'  ? true : (category == 'new' || category == 'success' || category == 'all') ? false : false ,
                    // firstname: window.firstname,
                    // lastname: window.lastname,
                    leadid: parseInt(window.leadid)
                })}>Добавить показ</Button> : null}
                </Grid>
                    <Grid item xs={12}>
                <FieldsComponent uploadingData={uploadingData}
                                 fields={filteredFields}
                                 category={category}
                                 _control={control}
                                 setChanged={setChanged}
                                 _register={register}
                                 setValue={setValue}
                                 remove={remove}
                                 replace={replace}
                                 watch={watch}
                                 update={update}
                                 setSaving={setProcessSaving}
                                 deleteItem={deleteItem}
                                 changed={changed}
                                 setContact={setContact}
                                 onSubmit={onSubmit}
                                 onError={onError}
                                 handleSubmit={handleSubmit}

                />

                    </Grid>


                <Grid item xs={12}>
                {!uploadingData ?
                    <Button  disabled={!changed} size={'large'} className={'save'} variant="contained" color="success"
                            type={'submit'}>Сохранить
                        изменения</Button> : null}
                </Grid>
                    <Grid item={12}>
                        {!processSaving && !changed ?
                            wasUploaded ?
                                error ? <Alert className={'alert'} severity="error">Возникла ошибка</Alert> :
                                    <Alert className={'alert'} severity="success">Данные обновлены</Alert>
                                : null
                            : null
                        }
                    </Grid>
                </Grid>

            </form>
            { window.leadid === 'all' ?


                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={page}
                        onChange={(event, newValue) => {
                            setPageType(newValue);
                        }}
                    >
                        <BottomNavigationAction label="Сделки" />
                        <BottomNavigationAction label="Все показы" />
                    </BottomNavigation>
                </Paper>
                :
                null

            }

        </>
    )
}

export default View